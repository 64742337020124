import React, {ReactElement} from "react";
import {ExternalLink} from "../../components/externalLInk/externalLink";

export function AboutContentSE(): ReactElement {
    return (
        <>
            <header>
                <h2>Om Rovbase</h2>
                <p className="about-ingress">
                    På Rovbase.se visas uppgifter från rovdjursinventeringar, besiktningar av skador orsakade av rovdjur och 
                    om rovdjur som fällts vid jakt eller som dött av annan orsak. Uppgifterna rör främst de stora rovdjuren 
                    björn, järv, lodjur och varg.
                </p>
            </header>
            <p>
                Databasen Rovbase är ett viktigt verktyg i förvaltningen av de stora rovdjuren. I databasen lagras uppgifter 
                om rovdjurens förekomst, om skador rovdjuren orsakar och om döda rovdjur. Lagringen av uppgifter görs av 
                Statens naturoppsyn (SNO), Veterinærinstituttet, länsstyrelserna, Statens veterinärmedicinska anstalt, 
                laboratorier som utför DNA-analyser, med flera. Uppgifterna i Rovbase ligger till grund för beräkningar av 
                rovdjursbeståndens storlek och utbredning samt för statistik över skador. 
            </p>
            <p>
                Mer om rovdjursförvaltningen och vem som gör vad kan du läsa på <ExternalLink href={"https://www.miljodirektoratet.no"}>Miljødirektoratets</ExternalLink> och <ExternalLink href={"http://www.naturvardsverket.se/"}>Naturvårdsverkets</ExternalLink> webbplatser.
            </p>
            <h3>Vilka uppgifter finns i Rovbase?</h3>
            <p>
                I Rovbase kan du ta del av uppgifter från rovdjursinventeringar, om skador orsakade av rovdjur och om döda rovdjur. 
                Personuppgifter och uppgifter som omfattas av sekretess visas inte.
            </p>
            <h4>Inventering av de stora rovdjuren</h4>
            <p>
                Inventering av järv, lodjur och varg sker enligt metodik som är gemensam för Norge och Sverige, medan 
                inventeringen av björn sker på liknande sätt i de båda länderna. Inventeringsmetodiken ingår i Norges 
                nationella övervakningsprogram för rovdjur. 
                Läs mer på <ExternalLink href={"https://www.miljodirektoratet.no"}>Miljødirektoratets</ExternalLink> och <ExternalLink href={"http://www.naturvardsverket.se/"}>Naturvårdsverkets</ExternalLink> webbplatser.
            </p>
            <p>
                I Rovbase hittar du uppgifter om rovdjursobservationer gjorda av inventeringspersonal från SNO, 
                länsstyrelsen eller de svenska samebyarna. En stor del av uppgifterna utgörs av observationer som 
                rapporterats till inventeringspersonal som därefter bedömt riktigheten i uppgifterna. I Rovbase finns 
                även de prover av biologiskt material som samlats in i samband med inventering. Proverna kan exempelvis 
                vara från spillning, urin eller hår. När DNA analyserats i proverna visas även laboratoriets analyssvar 
                för respektive prov.
            </p>
            <p>
                Fynd gjorda vid lyor eller av järv under perioden 1 mars – 31 aug eller av varg under perioden 1 maj –
                31 juli visas inte.
            </p>
            <h4>Döda rovdjur </h4>
            <p>
                I Rovbase hittar du uppgifter om djur av arterna björn, järv, lodjur och varg som fällts vid jakt eller 
                som dött av annan orsak. I många fall utförs åldersbestämning av Norsk institutt for naturforskning i 
                Norge eller SVA i Sverige, och DNA i vävnadsprover från djuren analyseras för att fastställa vilken 
                rovdjursindivid det handlar om. Dessa uppgifter framgår i respektive post.
            </p>
            <h4>Skador av stora rovdjur</h4>
            <p>
                I Rovbase hittar du uppgifter om skador på tamdjur, orsakade av björn, järv, kungsörn, lodjur eller 
                varg och som besiktigats av SNO (Norge), länsstyrelsen eller samebymedlemmar (Sverige). I Sverige är 
                endast skador på ren tillgängliga på Rovbase.se.
            </p>
            <p>
                Observera att skador på ren anmäls och besiktigas i liten utsträckning i Sverige. Det beror på att 
                ersättningen för rovdjurens skador på ren främst bygger på hur många rovdjur som finns inom respektive 
                samebys marker och inte på enskilda skador.
            </p>
            <p>
                På Rovbase.no finns statistik över förluster inom får- och rennäringen orsakade av rovdjur samt 
                utbetalade ersättningsbelopp .
            </p>
            <p>
                Mer om ersättningssystemen för skador orsakade av rovdjur finns att läsa på <ExternalLink href={"https://www.miljodirektoratet.no"}>Miljødirektoratets</ExternalLink>, <ExternalLink href={"http://www.naturvardsverket.se/"}>Naturvårdsverkets</ExternalLink> och <ExternalLink href={"https://www.sametinget.no/"}>Sametingets</ExternalLink> webbplatser.
            </p>
        </>
    )
}
