import React from 'react';
import { MenuBar } from '../menubar/menubar';
import { MenuButton } from '../menubutton/menubutton';
import { Footer } from '../footer/footer';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import './staticPageFrame.scss';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& a': {
                '&:active, &:visited, &:link': {
                    color: theme.palette.primary.main,
                },
            },
        },
    })
);

export default function StaticPageFrame(props: any): JSX.Element {
    const classes = useStyles({});
    const mobileScreen = useMobileBreakpointInnsyn();
    return (
        <div className="content-wrapper">
            <MenuBar />
            {!mobileScreen && <MenuButton />}
            <main className={classes.root}>{props.children}</main>
            <Footer />
        </div>
    );
}
