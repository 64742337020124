import {useMediaQuery} from '@material-ui/core';

export function useMobileBreakpointInnsyn(): boolean {
    return useMediaQuery('(max-width:599px)');
}

export function useTabletBreakpointInnsyn(): boolean {
    return useMediaQuery('(max-width:959px)');
}

export function useDesktopBreakpointInnsyn(): boolean {
    return useMediaQuery('(min-width:1450px)');
}